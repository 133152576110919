import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout
    style={{
      fontFamily: `Brandon Text`,
      fontWeight: 400,
      lineHeight: 1.4,
      color: `#393939`,
    }}
  >
    <SEO
      title="Resume"
      keywords={[`resume`, `application`, `truecar`, `thomasliu`]}
    />

    <section className="row">
      <div className="col-3">
        <div className="background-info">
          <h2>Contact</h2>
          <p>
            <a href="mailto:thomasl@hey.com">thomasl@hey.com</a>
          </p>
          <p>
            <a href="tel:2133080159">(213)308-0159</a>
          </p>
          <p>Los Angeles, CA</p>
          <p>
            <a
              href="https://truecar.thomasliu.com"
              target="__blank"
              rel="noopener"
            >
              truecar.thomasliu.com
            </a>
          </p>
        </div>
        <div className="background-info">
          <h2>Education</h2>
          <p>
            University of California, Irvine <br />
            B.S. Computer Science
          </p>
        </div>
        <div className="background-info">
          <h2>Awards</h2>
          <p>
            HackUCI Spring 2014 <br />
            Best Hack using the Rdio API <br />
            Top 10 award
          </p>
        </div>
      </div>
      <div className="col-9 experience">
        <section>
          <h2>Experience</h2>
          <div className="experience__details">
            <h3>Alltrue (formerly CauseBox) - Software Engineer</h3>
            <p>Oct 2021 - Current</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Developed and shipped user-facing features using
                component-based UI with Vue.js.
                <br />
                - Developed using best practices in front-end architecture,
                including performance, security and usability.
                <br />
                - Worked with cross-functional teams. Collaborating closely with
                designers, engineers, and product managers.
                <br />
                - Identified and resolved performance and scalability issues.
                <br />
                - Delivered features within tight deadlines and iterated on old
                and new features.
                <br />
                - Utilized responsive and cross-browser/platform testing.
                <br />- Collaborated with developers on design techniques and
                coding standards.
              </li>
              <li>
                Technologies: Vue.js, Webpack, ES6, Cypress.js, Storybook.js,
                HTML/CSS, PHP, MySQL, Figma, Docker, CGP, Shortcut, Github,
                Cloudflare, Datadog
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="experience__details">
            <h3>Brandastic - Developer</h3>
            <p>2017 - Oct 2021</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Developed cross-browser and responsive UI applications with
                React, WordPress, and PHP.
                <br />
                - Managed and supported production environments for system
                reliability and uptime.
                <br />
                - Mentor and train other team members on design techniques and
                coding standards.
                <br />
                - Automated application deployments. Reduced risk and time
                required to deploy to production.
                <br />- Worked closely with customers and the project management
                team to gather requirements, create timeline estimates, and keep
                projects on track.
              </li>
              <li>
                Technologies: React.js, Webpack, Gulp, ES6, jQuery, REST, Styled
                Components, HTML/SCSS/CSS, PHP, MySQL, Apache, Ubuntu, CentOS,
                Linode, JIRA, Bitbucket, Cloudflare, Confluence, Deploybot
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h2>Personal Projects</h2>
          <div className="experience__details">
            <h3>Static site generators and server side rendering</h3>
            <p>2019</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Experimented with Gatsby.js as a static site generator (SSG).
                <br />
                - Assessed the state of SSR (server side rendering via
                javascript), link prefetching, image optimizations, and site
                speed.
                <br />- This resume is built with Gatsby.js and hosted on
                Netlify. It is mobile friendly and print friendly.
              </li>
              <li>Technologies: Gatbsy.js, GraphQL, React.js, Netlify</li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Microservices and API's</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Created microservices and API's: An image search abstraction
                layer powered by Bing’s image search API, a URL shortening
                service API, a request header parser API, and a timestamp
                microservice API.
              </li>
              <li>Technologies: Node.js, Express.js, MongoDB, Mongoose</li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Full stack IMDB clone (Fabflix)</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Created a full stack IMDB website clone called Fabflix.
                Implemented features for user login validation and
                authentication, search and filtering, and user session handling.
                The app was then deployed onto AWS via EC2 and RDS with MySQL as
                the database.
              </li>
              <li>
                Technologies: AWS (EC2, RDS, Route53), Java, Java Servlets/JSP,
                JDBC/MySQL, Tomcat Apache
              </li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Mood Music - HackUCI</h3>
            <p>2014</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Mood Music started off as an ambitious idea to provide web
                users with content-relevant music that reflects the mood of the
                websites they visit.
                <br />- Combined text extraction, natural language processing to
                determine mood, and integrated with the Rdio API to create a
                Firefox add-on.
              </li>
              <li>Technologies: Javascript, HTML/CSS, Python</li>
            </ul>
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
